import React from 'react'
import logo from '../img/logo.png'


const Navbar = () => (
    <header className="navbar navbar-expand navbar-dark flex-column flex-md-row fixed-top bg-dark">
        <div className="container">
            <button className='navbar-toggler' data-target='#collapsingNavbar' data-toggle='collapse' type='button'>
                <span>☰</span>
            </button>
            <div className="navbar-brand">
                <a href="https://www.maisons-inea.fr/" target="_blank"  rel="noopener noreferrer" className="navbar-item">
                    <figure className="image">
                        <img src={logo}  alt="Logo Conity" className="logo"/>
                    </figure>
                </a>
            </div>

        </div>
    </header>
)

export default Navbar
